import axios from "axios/index";

export const list = (plateNumber, phone, plateColor, page, pageSize) => {
  return axios.get("/plateNumber/specialList", {
    params: {
      phone,
      plateNumber,
      plateColor,
      page,
      pageSize,
    },
  });
};
export const delSpecialPlate = plateNumberId => {
  return axios.delete(`/plateNumber/${plateNumberId}/del`);
};
export const save = vo => {
  return axios.post("/plateNumber/saveSpecialPlate", vo);
};
